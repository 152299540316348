// import * as Sentry from '@sentry/browser';
import '@fontsource-variable/inter';
import './styles/app.scss';

// Window extensions
import './js/extensions';

// start the Stimulus application
import './stimulus/bootstrap';
import Tracker from './js/Tracker';

const version = require('../package.json').version;

window.playerVersion = version;

window.onload = () => {
    setTimeout(() => {
        const tracker = new Tracker('https://tracker.bang.com');
        tracker.trackVisit();
    }, 3000);

    //     Sentry.init({
    //         dsn: 'https://de910e378e514c7bb499994e82644f5b@o69286.ingest.sentry.io/148116',
    //         environment: window.env,
    //         ignoreErrors: ['ChunkLoadError', 'AbortError', 'The play method is not allowed', 'sendBeacon'],
    //         denyUrls: [
    //             /static\.cloudflareinsights\.com/i,
    //             /www\.gstatic\.com/i,
    //             // Chrome extensions
    //             /extensions\//i,
    //             /^chrome:\/\//i,
    //         ],
    //     });
    //
    //     if (window.user.email) {
    //         Sentry.setUser({email: window.user.email});
    //     }
};
