window.isMobile = /iPhone|Android/i.test(navigator.userAgent);

window.getCookie = function (cname) {
    const name = `${cname}=`;

    try {
        const ca = document.cookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }

            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }

        return '';
    } catch (e) {
        return '';
    }
};

window.setCookie = function (name, value, days2expire = 2, path = '/', domain = '') {
    const date = new Date();
    date.setTime(date.getTime() + days2expire * 24 * 60 * 60 * 1000);
    let expires = date.toUTCString(),
        newcookie = `${name}=${value};` + `expires=${expires};` + `path=${path};`;

    if (domain.length > 0) {
        newcookie += `domain=${domain}`;
    }

    document.cookie = newcookie;
};

window.showErrorMessage = function (message, duration = 2000) {
    if (message.trim() !== '') {
        let sanitizedMessage = message.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');

        window.dispatchEvent(
            new CustomEvent('showErrorMessage', {
                detail: {
                    message: sanitizedMessage,
                    duration: duration,
                },
            })
        );
    }
};

window.showSuccessMessage = function (message, duration = 2000) {
    if (message.trim() !== '') {
        let sanitizedMessage = message.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');

        window.dispatchEvent(
            new CustomEvent('showSuccessMessage', {
                detail: {
                    message: sanitizedMessage,
                    duration: duration,
                },
            })
        );
    }
};

window.isPreviewRendered = function () {
    return document.documentElement.hasAttribute('data-turbo-preview');
};

window.changeLanguage = function (lang) {
    let url = new URL(window.location.href);

    url.searchParams.delete('lang');
    url.searchParams.append('lang', lang);

    window.location.href = url.href;
};

window.handleGoogleLogin = function (response) {
    const formData = new FormData();
    formData.append('credential', response.credential);

    fetch('/google/one-tap/login', {
        method: 'POST',
        body: formData,
        credentials: 'include',
    }).then((response) => {
        if (response.ok) {
            window.showSuccessMessage('Welcome back!');
            window.location.reload();
        } else {
            window.showErrorMessage('Login error, please try again later!');
        }
    });
};

window.sanitizeInput = function (input) {
    return input.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
};
