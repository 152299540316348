export default class Tracker {
    constructor(url) {
        this.url = url;
    }

    static toQueryString(params) {
        Object.keys(params).forEach((key) => {
            if (params[key] === undefined || params[key] === null) {
                delete params[key];
            }
        });

        const query = new URLSearchParams(params);

        return query.toString();
    }

    static buildData() {
        const url = window.location.href,
            user = window.user || null,
            session_id = Tracker.getSessionId(),
            siteId = window.tsid || 100;

        return {
            url,
            type: url.indexOf('/video/') > 0 ? 'video' : 'non-video',
            user_id: user?.id,
            session_id,
            site_id: siteId,
            link_id: this.getQueryItem(['link_id', 'lid', 'l']),
            tracker_id: this.getQueryItem(['tracker_id', 'tid', 't']),
            aff: this.getQueryItem(['aff'], window.aff || null),
            custom_variable: this.getQueryItem(['cv']),
            referrer: document.referrer,
            promo: this.getQueryItem(['p', 'promo']),
        };
    }

    static getSessionId() {
        try {
            const sessionId = window.localStorage.getItem('tracker.sessionId');

            return sessionId || window.getCookie('tracker_session_id') || null;
        } catch (e) {
            return window.getCookie('tracker_session_id') || null;
        }
    }

    static getQueryItem(names) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        for (const name of names) {
            if (urlParams.has(name)) {
                return urlParams.get(name);
            }
        }

        return undefined;
    }

    async trackVisit() {
        let response = await fetch(`${this.url}/visit?${Tracker.toQueryString(Tracker.buildData())}`, {
            method: 'GET',
            credentials: 'include',
            cache: 'no-cache',
        }).catch((err) => console.warn('Tracker error: ' + err));

        if (typeof response !== 'undefined') {
            const sessionId = response.headers.get('X-Session-Id');

            try {
                window.localStorage.setItem('tracker.sessionId', sessionId);
            } catch (e) {
                // localStorage is disabled
            }

            window.setCookie('tracker_session_id', sessionId, 2, '/');

            return await response.text();
        }
    }
}
